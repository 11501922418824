import React from "react"

import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Seo from "../components/Seo"

const ThanksPage = (props) => {

  const pageData = props.data.wpPage;
  const seoData = props.data.wpPage.seo;
  return (
    <Layout extraClass="generic" pageId={pageData.databaseId}>
      <Seo 
        title={seoData.title}
        description={seoData.metaDesc}
        canonical={seoData.canonical}
        keywords={seoData.metaKeywords}
      />
      <div className="container justify-content-center">
        <div className="copy text-center">
          <h1>{pageData.title}</h1>
          <div dangerouslySetInnerHTML={{
              __html: pageData.content,
            }} />
        </div>
      </div>
    </Layout>
  )
}

export default ThanksPage

export const thanksQuery = graphql`
query thanksQuery {
  wpPage(databaseId: { eq: 192 }) {
      databaseId
      title
      content
      seo {
        title
        canonical
        metaDesc
        metaKeywords
      }
  }
}
`
